import React from 'react';
import gfx_logo from "../gfx/logo2.png";
import {Link} from "react-router-dom";

const Header = () => (
    <div className="container">
        <div className="row border-bottom">
            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <Link to="/">
                    <img src={gfx_logo} alt="SUPER AWESOME BAND" className="w-100"/>
                </Link>
            </div>
        </div>
    </div>
);

export default Header;