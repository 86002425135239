import React from 'react';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className="Footer bg-secondary mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center py-2">
                        <Link to="/impressum" className="text-info mx-2">
                            Impressum
                        </Link>
                        <Link to="/datenschutz" className="text-info mx-2">
                            Datenschutz
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Footer;