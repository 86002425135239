import React from 'react';

const Imprint = () => (
    <div className="container mt-5">
        <div className="row">
            <div className="col-12">
                <h2>Impressum</h2>
                <p className="mt-5">
                    <b>Informationspflicht laut § 5 TMG.</b>
                </p>
                <p>
                    Backslash Seven GmbH<br/>
                    Großbeerenstr. 12<br/>
                    10963 Berlin<br/>
                    GERMANY
                </p>
                <p>
                    UST-ID: DE310801451<br/>
                    Register: Handelsregister<br/>
                    Registernummer: HRB 18675 B<br/>
                    Registergericht: Amtsgericht Charlottenburg<br/>
                    Geschäftsführer: Pascal Weber, Volkram Weber
                </p>
                <p>
                    mail@superawesomeband.de
                </p>
                <p className="mt-4">
                    <b>EU-Streitschlichtung</b>
                </p>
                <p>
                    Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br/>
                    Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter http://ec.europa.eu/odr?tid=321147720 zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.<br/>
                    Wir möchten Sie jedoch darauf hinweisen, dass wir nicht verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                </p>
                <p className="mt-3">
                    <b>Haftung für Inhalte dieser Website</b>
                </p>
                <p>
                    Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Laut Telemediengesetz (TMG) §7 (1) sind wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung bereitstellen, nach den allgemeinen Gesetzen verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br/>
                    Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.<br/>
                    Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.
                </p>
                <p className="mt-3">
                    <b>Haftung für Links auf dieser Website</b>
                </p>
                <p>
                    Unsere Webseite enthält gelegentlich Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.<br/>
                    Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
                </p>
            </div>
        </div>
    </div>
);

export default Imprint;