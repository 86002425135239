import React from 'react';

const DataProtection = () => (
    <div className="container mt-5">
        <div className="row">
            <div className="col-12">
                <h2>Datenschutzerklärung</h2>

                <p className="mt-5">
                    <b>Datenschutz</b>
                </p>
                <p>
                    Wir haben diese Datenschutzerklärung (Fassung 31.10.2021-321147720) verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.<br/>
                    Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
                </p>

                <p>
                    <b>TLS-Verschlüsselung mit https</b>
                </p>
                <p>
                    Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung Artikel 25 Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.
                </p>

                <p>
                    <b>Rechte laut Datenschutzgrundverordnung</b>
                </p>
                <p>
                    Recht auf Berichtigung (Artikel 16 DSGVO)<br/>
                    <br/>
                    Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)<br/>
                    <br/>
                    Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)<br/>
                    <br/>
                    Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)<br/>
                    <br/>
                    Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)<br/>
                    <br/>
                    Widerspruchsrecht (Artikel 21 DSGVO)<br/>
                    <br/>
                    Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
                </p>

                <p>
                    <b>
                        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) wenden.
                    </b>
                </p>

                <p>
                    <small>
                        Quelle: Datenschutzerklärung & Impressum erstellt mit dem Datenschutz Generator von AdSimple in Kooperation mit slashtechnik.de
                    </small>
                </p>
            </div>
        </div>
    </div>
);

export default DataProtection;