import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';
import Home from './Home';
import './theme/theme.scss';
import Footer from "./elements/Footer";
import Header from "./elements/Header";
import Imprint from "./Imprint";
import DataProtection from "./DataProtection";

const App = () => (
    <Fragment>
        <Header/>
        <Switch>
            <Route exact path="/impressum" component={Imprint}/>
            <Route exact path="/datenschutz" component={DataProtection}/>
            <Route exact path="/" component={Home}/>
        </Switch>
        <Footer/>
    </Fragment>
);

export default App;
