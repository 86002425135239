import React from 'react';
import YoutubeVideo from "./YoutubeVideo";

class Home extends React.Component {
  render() {
    return (
      <div className="Home">
        <div className="container">
            <div className="row mt-4">
                <div className="col-12 col-md-6 mb-3">
                    <YoutubeVideo youtubeId="NNvABf-ye88"/>
                </div>
                <div className="col-12 col-md-6 mb-3">
                    <YoutubeVideo youtubeId="5NyKR5HtaHE"/>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 text-center">
                    <h2>
                        SUPER AWESOME ist Punk Rock aus Berlin!
                    </h2>
                    <p>
                        In einer lauen, durchzechten Sommernacht 2010 wird SUPER AWESOME unter sagenumwobenen Umständen von den Cousins Pascal Weber und Volkram Weber gegründet. Aus der Schnapsidee entsteht schon wenige Tage später ein ernsthaftes Projekt mit den ersten musikgeschichtlich relevanten Kompositionen. Einige Jahre sowie unzählige Demotapes, Bierkisten und Schlagzeuger später entsteht endlich die heutige, vierköpfige Berliner Formation mit den beiden Gründern (Gitarren und Vocals) sowie Angelo Kolb (Bass) und schließlich Jacob Letz (Schlagzeug). Mit den bereits 2013 begonnenen Aufnahmen zum ersten Album wird der erste Meilenstein der Bandgeschichte anvisiert. Im Sommer 2014 feiert die Band dann auch ihr Debüt auf der Bühne. Im Frühjahr 2015 wird endlich das (vor Allem von den Bandmitgliedern) lang ersehnte Debütalbum ROCKET IN A STRAITJACKET veröffentlicht. Ein weiteres Album ist schon in Aussicht...
                    </p>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 text-center">
                    <h2>
                        LIVE
                    </h2>
                </div>
                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 mt-3">
                    <table className="table">
                        <tbody>
                        <tr>
                            <td>
                                2018-02-03
                            </td>
                            <td>
                                Berlin
                            </td>
                            <td>
                                MAZE
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2018-02-01
                            </td>
                            <td>
                                Berlin
                            </td>
                            <td>
                                Hangar49
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2017-10-20
                            </td>
                            <td>
                                Berlin
                            </td>
                            <td>
                                Mensch Meier
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2017-07-14
                            </td>
                            <td>
                                Flörsheim
                            </td>
                            <td>
                                Flörsheimer Open-Air
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2017-01-19
                            </td>
                            <td>
                                Berlin
                            </td>
                            <td>
                                Junction Bar
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2016-09-10
                            </td>
                            <td>
                                Berlin
                            </td>
                            <td>
                                Wild At Heart
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2016-08-20
                            </td>
                            <td>
                                Fischbachtal
                            </td>
                            <td>
                                Nonstock Festival
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2016-06-11
                            </td>
                            <td>
                                Berlin
                            </td>
                            <td>
                                Kellerrockbar
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2016-03-25
                            </td>
                            <td>
                                Schwarzwald
                            </td>
                            <td>
                                In The Middle Of Nowhere
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2015-06-19
                            </td>
                            <td>
                                Hamburg
                            </td>
                            <td>
                                Pooca-Bar
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2015-05-20
                            </td>
                            <td>
                                Berlin
                            </td>
                            <td>
                                Cassiopeia
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2015-04-16
                            </td>
                            <td>
                                Berlin
                            </td>
                            <td>
                                Culture Container
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2015-03-27
                            </td>
                            <td>
                                Berlin
                            </td>
                            <td>
                                Villa Kuriosum
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2014-11-11
                            </td>
                            <td>
                                Berlin
                            </td>
                            <td>
                                Weisse Rose
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2014-09-13
                            </td>
                            <td>
                                Berlin
                            </td>
                            <td>
                                Walde
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2014-06-08
                            </td>
                            <td>
                                Schwarzbach
                            </td>
                            <td>
                                Schöndraussen-Festival
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12 text-center">
                    <h2>
                        KONTAKT
                    </h2>
                    <p>
                        <a href="mailto:mail@superawesomeband.de">
                            mail@superawesomeband.de
                        </a>
                    </p>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Home;
